import {Responsive, ResponsiveSection} from '../../../styles.interface';
import {config} from '@/product/config.json';

const Register: ResponsiveSection = {
  CreditCardBox: {
    xs: {
      background: config.STYLEGUIDE.colors.lighter,
      'border-color': config.STYLEGUIDE.colors.light,
    },
  }
};

const Player: ResponsiveSection = {
  FavoriteButton: {
    xs: {
      background: config.STYLEGUIDE.colors.info2,
    },
  }
};

const OldPasswordForm: ResponsiveSection = {
  ButtonStyled: {
    xs: {
      background: config.STYLEGUIDE.colors.brand,
    },
  },
  ButtonStyledHover: {
    xs: {
      background: config.STYLEGUIDE.colors.infoHover,
    },
  },
};

const Sections: Responsive = {
  Register,
  Player,
  OldPasswordForm,
};

export default Sections;
